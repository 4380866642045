import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VExpansionPanels,{staticClass:"ExpansionComponent ExpansionBordered mt-6"},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"ExpansionTitle",attrs:{"expand-icon":""},on:{"click":function($event){return _vm.changeStateExpansive()}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VIcon,{staticClass:"iconExpand"},[_vm._v(" "+_vm._s(_vm.iconExpansive)+" ")])]},proxy:true}])},[_vm._v(" Account Payment Balance "),_c('div',{staticClass:"ExpansionState HideOnMovil"},[_vm._v(" "+_vm._s(_vm.stateExpansiveMessage)+" ")])]),_c(VExpansionPanelContent,[_c('div',{staticClass:"ExpandContent"},[_c('div',{staticClass:"LeftInputs"},[_c('div',{staticClass:"InputContent mb-3"},[_c(VTextField,{attrs:{"label":"Reference Numer"}})],1),_c('div',{staticClass:"InputContent mb-3"},[_c(VTextField,{attrs:{"label":"Cedent"}})],1),_c('div',{staticClass:"InputContent mb-3"},[_c(VSelect,{attrs:{"label":"Country"}})],1),_c('div',{staticClass:"InputContent"},[_c(VMenu,{attrs:{"close-on-content-click":false,"offset-y":"","min-width":"auto","content-class":"elevation-3","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Inception Date","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c(VDatePicker,{attrs:{"no-title":"","color":"#003D6D"}})],1)],1)]),_c('div',{staticClass:"RightInputs"},[_c('div',{staticClass:"ExchangeInfo mb-3"},[_vm._v(" Exchange rate when the policy was issued"),_c('br'),_c('b',[_vm._v("1 USD = 20.18 MXN")])]),_c('div',{staticClass:"InputContent mb-3"},[_c(VSelect,{attrs:{"label":"Local Currency"}})],1),_c('div',{staticClass:"InputContent mb-3"},[_c(VTextField,{attrs:{"prefix":"$","label":"Exchange rate at the Payment Date"}})],1),_c('div',{staticClass:"InputContent mb-3"},[_c(VTextField,{attrs:{"label":"Expected Payment in Local C."}})],1),_c('div',{staticClass:"InputContent mb-3"},[_c(VTextField,{attrs:{"label":"Expected Payment in USD"}})],1),_c('div',{staticClass:"InputContent mb-3"},[_c(VTextField,{attrs:{"prefix":"$","label":"Payment Received in Local C."}})],1),_c('div',{staticClass:"InputContent mb-3"},[_c(VTextField,{attrs:{"prefix":"$","label":"Payment Received in USD"}})],1),_c('div',{staticClass:"InputContent mb-3"},[_c(VTextField,{attrs:{"prefix":"$","label":"Payment Gap in Local C."}})],1),_c('div',{staticClass:"InputContent mb-3"},[_c(VTextField,{attrs:{"prefix":"$","label":"Payment Gap un USD"}})],1)]),_c('div',{staticClass:"finishButtonCont mt-7 d-flex justify-end align-center"},[_c(VBtn,{staticClass:"secondaryBtn",attrs:{"rounded":"","large":"","text":""}},[_vm._v(" Define ")]),_c(VBtn,{staticClass:"finishBtn ml-3",attrs:{"rounded":"","large":"","text":"","outlined":""}},[_vm._v(" Validate ")])],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }